import { useEffect, useState } from 'react'

import { Alert, Button, Menu, Modal } from 'easyship-components'
import Tab from '@/components/Tab'
import QuantityInput from '@/components/QuantityInput'
import useGetAvailableBinsTypeQuery from './hooks/useGetAvailableBinsTypeQuery'
import { BinTypeDetails } from './models/BinType'
import { CircleCross } from 'easyship-components/icons'
import { useCreateMultipleBinsMutation } from './hooks/useCreateMultipleBinsMutation'
import { toast } from 'react-toastify'
interface NewContainerProps {
  open: boolean
  onClose: () => void
}

export interface NewBinFormData {
  binTypeSlug: string
  courierSlug: string | null
  quantity: number
}

const defaultBinFormData: NewBinFormData = {
  binTypeSlug: '',
  courierSlug: '',
  quantity: 0,
}

export default function NewContainerDialog({ open, onClose }: NewContainerProps) {
  const { data: binsType } = useGetAvailableBinsTypeQuery()
  const createMultipleBinsMutation = useCreateMultipleBinsMutation()
  const [formData, setFormData] = useState<NewBinFormData[]>([])
  const [selectedTab, setSelectedTab] = useState<'courier' | 'other'>('courier')

  const tableOptions = selectedTab === 'courier' ? binsType?.couriers : binsType?.binTypes

  const totalQuantity = formData.reduce((acc, data) => acc + data.quantity, 0)

  function isFormValid() {
    if (formData.length === 0) {
      return false
    }
    if (totalQuantity > 200) {
      return false
    }
    if (formData.some((data) => data.quantity > (selectedTab === 'courier' ? 100 : 20))) {
      return false
    }
    return true
  }

  function updateFormData(updates: Partial<NewBinFormData>) {
    setFormData((prevData) =>
      updates.quantity === 0
        ? prevData.filter((item) =>
            selectedTab === 'courier'
              ? item.courierSlug !== updates.courierSlug
              : item.binTypeSlug !== updates.binTypeSlug,
          )
        : [
            ...prevData.filter((item) =>
              selectedTab === 'courier'
                ? item.courierSlug !== updates.courierSlug
                : item.binTypeSlug !== updates.binTypeSlug,
            ),
            updates as NewBinFormData,
          ],
    )
  }

  function handleQuantityChange(quantity: number, type: BinTypeDetails) {
    updateFormData({
      quantity: quantity,
      binTypeSlug: selectedTab === 'courier' ? 'courier' : type.slug,
      courierSlug: selectedTab === 'courier' ? type.slug : null,
    })
  }

  function handleRemoveBin(data: NewBinFormData) {
    setFormData((prevData) =>
      prevData.filter((item) =>
        selectedTab === 'courier'
          ? item.courierSlug !== data.courierSlug
          : item.binTypeSlug !== data.binTypeSlug,
      ),
    )
  }

  function handleCreateBins() {
    if (isFormValid()) {
      createMultipleBinsMutation.mutate(
        { bins: formData },
        {
          onSuccess: () => {
            onClose()
            toast.success('Containers created successfully')
          },
        },
      )
    }
  }

  useEffect(() => {
    setFormData([])
  }, [selectedTab])

  return (
    <Modal
      data-testid="new-container-dialog"
      open={open}
      title="Create Containers"
      onClose={onClose}
      dismissOnClickBackdrop
      dismissOnEscape
    >
      <Modal.Header title="Create Containers" closeButtonAriaLabel="Close modal" />
      <Modal.Content className="w-[90vw] lg:w-[1024px]">
        <div className="mb-8 flex border-b border-sky-500">
          <Tab
            title="Courier Containers"
            selected={selectedTab === 'courier'}
            onTabChange={() => setSelectedTab('courier')}
          />
          <Tab
            title="Other Containers"
            selected={selectedTab === 'other'}
            onTabChange={() => setSelectedTab('other')}
          />
        </div>

        <div className="grid grid-cols-1 gap-y-10 lg:grid-cols-3 lg:gap-10">
          <div className="col-span-2 w-full">
            <div className="mb-3 flex items-center justify-between">
              <div className="font-bold text-ink-500">Add courier containers:</div>
              <div className="italic text-ink-100">max 200 containers total</div>
            </div>
            <table className="w-full">
              <tbody>
                {tableOptions?.map((type) => (
                  <tr key={type.slug} className="border border-sky-500">
                    <td className="w-full border-r border-sky-500  px-3 py-2">
                      <div className="flex items-center justify-between text-xl">
                        {type.name}{' '}
                        <span className="text-base italic text-ink-100">
                          max {selectedTab === 'courier' ? 100 : 20}
                        </span>
                      </div>
                    </td>
                    <td className="w-[190px] p-2">
                      <QuantityInput
                        max={selectedTab === 'courier' ? 100 : 20}
                        quantity={
                          formData.find((data) =>
                            selectedTab === 'courier'
                              ? data.courierSlug === type.slug
                              : data.binTypeSlug === type.slug,
                          )?.quantity as number
                        }
                        onValueChange={(quantity) => handleQuantityChange(quantity, type)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <div className="mb-3 font-bold text-ink-500 ">
              You are creating the following containers:
            </div>
            <div className="border border-sky-500 p-6">
              {formData.length === 0 && (
                <div className="text-lg text-ink-100">Add containers to see them appear here</div>
              )}
              {formData.length > 0 && (
                <div>
                  {formData.map((data, index) => (
                    <div key={index} className="flex items-center justify-between">
                      <div className="text-lg">
                        <span className="text-xl">{data.quantity}</span> X{' '}
                        {selectedTab === 'courier' ? data.courierSlug : data.binTypeSlug}
                      </div>
                      <CircleCross
                        className="cursor-pointer fill-ink-100"
                        onClick={() => handleRemoveBin(data)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {totalQuantity > 200 && (
              <Alert className="mt-6" severity="error">
                <div data-testid="error-containers">
                  Maximum limit of 200 containers exceeded. Please reduce the number of containers
                  to 200 or less.
                </div>
              </Alert>
            )}
            <Button
              loading={createMultipleBinsMutation.isLoading}
              type="submit"
              onClick={handleCreateBins}
              disabled={!isFormValid()}
              className="mt-6 w-full"
              color="primary"
            >
              create {totalQuantity} containers
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}
