import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'
import { CreateMultipleBins } from '../models/Bin'

interface PrintQueueMutationParams {
  queueId: number
}

export function usePrintQueueMutation() {
  const { binsGateway } = useGateways()

  return useMutation({
    mutationFn: ({ queueId }: PrintQueueMutationParams) => binsGateway.printQueue(queueId),
    onError: (reason) => toastApiError(reason),
  })
}
