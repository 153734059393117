import Chance from 'chance'

import PrintQueue from '@/ViewContainers/models/PrintQueue'

const chance = new Chance()

export const printQueueStub = (): PrintQueue[] => [
  {
    id: 1,
    status: 'pending',
    createdAt: '2022-11-15T07:16:27.693Z',
    printQueueItems: [
      {
        id: 1,
        status: 'pending',
        barcodeLargeText: '45678901234567890',
        barcodeNote: '456789012',
        barcodeText: '45678901234567890',
        printQueueId: 1,
        title: '456',
      },
      {
        id: 2,
        status: 'pending',
        barcodeLargeText: '456789012345',
        barcodeNote: '456789012',
        barcodeText: '456789012345',
        printQueueId: 2,
        title: '324',
      },
    ],
  },
]
